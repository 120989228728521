"use client";
import React from "react";
import { Form, Select, SelectProps } from "antd";
import { AppConsts } from "src/lib/constants";
import { OptionModel } from "@models/global";

const { Option } = Select;

export function renderOptions(options: any) {
  return (options || []).map((option: any, index: number) => (
    <Option key={index} value={option?.id || option?.value}>
      {option?.label || option?.name || option?.code}
    </Option>
  ));
}

const { formVerticalLayout } = AppConsts;
interface FormSelectProps {
  size?: "middle" | "small" | "large";
  label?: string;
  placeholder?: string;
  name: string | (string | number)[];
  rule?: any;
  options: OptionModel[];
  selectProps?: SelectProps<any>;
  disabled?: boolean;
  formClass?: string;
  filterOption?: any;
  showSearch?: boolean;
  onChange?: (value: any) => void;
  optionModal?: (item: any, index: any) => void;
  extra?: any;
  allowClear?: boolean;
}

const FormSelect: React.FC<FormSelectProps> = ({
  label,
  placeholder,
  name,
  rule,
  options,
  selectProps,
  disabled = false,
  filterOption,
  optionModal,
  onChange,
  formClass,
  size,
  showSearch = false,
  extra,
  allowClear,
}) => {
  return (
    <Form.Item
      label={label}
      name={name}
      rules={rule}
      className={formClass}
      extra={extra}
      {...formVerticalLayout}
    >
      <Select
        allowClear={allowClear}
        filterOption={filterOption ?? false}
        className="full-width"
        placeholder={placeholder}
        onChange={onChange}
        size={size ?? "large"}
        disabled={disabled}
        showSearch={showSearch}
        {...selectProps}
      >
        {optionModal
          ? (options || []).map((item, index) => optionModal(item, index))
          : renderOptions(options)}
      </Select>
    </Form.Item>
  );
};

export default FormSelect;
