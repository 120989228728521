"use client";
import { useTranslation } from "@/app/i18n/client";
import { useRouter } from "next/navigation";
import { useEffect, useState } from "react";
import Image from "next/image";
import defaultLogo from "@/src/images/logo.svg";
import { Form } from "antd";
import { cx } from "@/src/utils/all";
import { usePropertyStore } from "@stores/rootStoreProvider";

interface IProps {
  showSearch?: boolean;
  logo?: string;
}

function HeaderProperty({ showSearch = false, logo }: IProps) {
  const [scrollY, setScrollY] = useState(0);
  const propertyStore = usePropertyStore();
  const { t } = useTranslation("en", "common");
  const router = useRouter();
  const [form] = Form.useForm();

  const [showChild] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    // just trigger this so that the initial state
    // is updated as soon as the component is mounted
    // related: https://stackoverflow.com/a/63408216
    handleScroll();

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async () => {
    form.validateFields().then(async () => {
      const values = form.getFieldsValue() || {};
      if (values.query) {
        router.replace("/search?q=" + values.query);
      }
    });
  };

  const updatePropertyParam = async (val: string) => {
    propertyStore.setParams({ group: val });
  };

  if (!showChild) {
    return null;
  }

  return (
    <>
      <div className={cx("header-nav tabs", scrollY > 100 ? "active" : "")}>
        <a
          className={
            propertyStore.params.group === "sale"
              ? "text-blue-400 hover:text-blue-800"
              : "text-gray-600 hover:text-gray-900"
          }
          onClick={() => updatePropertyParam("sale")}
        >
          {t("header.property.buy")}
        </a>
        <a
          className={
            propertyStore.params.group === "rent"
              ? "text-blue-400 hover:text-blue-800"
              : "text-gray-600 hover:text-gray-900"
          }
          onClick={() => updatePropertyParam("rent")}
        >
          {t("header.property.rent")}
        </a>
      </div>
      <header className="header header-property">
        <div className="logo-wrapper">
          <a className="logo" href="/">
            <div className="logo-1h-wrapper">
              <span className="logo-1h">
                <Image src={logo ?? defaultLogo} fill={true} alt="logo" />
              </span>
            </div>
          </a>
        </div>

        <div className="search-container relative flex items-center justify-center">
          {showSearch && (
            <Form
              id="searchForm"
              form={form}
              onFinish={handleSubmit}
              autoComplete="off"
            >
              <div className="input">
                <i className="icon-search"></i>

                <Form.Item name="query" rules={[{ required: true }]}>
                  <input
                    type="text"
                    placeholder="Search on 1h"
                    className="focus:ring-0"
                  />
                </Form.Item>

                <input
                  className="secondary focus:ring-0"
                  type="text"
                  placeholder="or Google"
                />

                <input type="submit" />
              </div>
            </Form>
          )}
        </div>

        <div className="pull-right"></div>
      </header>
    </>
  );
}

export default HeaderProperty;
