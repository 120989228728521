"use client";
import { useState } from "react";
import { useTranslation } from "@/app/i18n/client";
import Drawer from "antd/lib/drawer";
import SignInForm from "./signin-form";
import SignUpForm from "./signup-form";
import LogoutButton from "../../logout-button";
import { User2 } from "lucide-react";

interface IProps {
  user: any;
}

export default function ButtonProfile({ user }: IProps) {
  const { t } = useTranslation("en", "common");
  const [showSidebar, setShowSidebar] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);
  const [showSignIn, setShowSignIn] = useState(false);

  const onProfileClick = () => {
    setShowSidebar(!showSidebar);
  };

  const hideShowSignUp = () => {
    setShowSignUp(!showSignUp);
  };

  const hideShowSignIn = () => {
    setShowSignIn(!showSignIn);
  };

  return (
    <>
      {user != null && user.name ? (
        <span
          className="cursor-pointer rounded-full border border-slate-200 p-1 hover:border-slate-400"
          onClick={onProfileClick}
        >
          <User2 className="text-slate-500" onClick={onProfileClick} />
        </span>
      ) : (
        <span className="flex gap-4">
          <span
            className="cursor-pointer font-semibold text-slate-400 duration-100 hover:text-slate-600"
            onClick={hideShowSignIn}
          >
            {t("btn.signin")}
          </span>
          <span
            className="cursor-pointer font-semibold text-slate-400 duration-100 hover:text-slate-600"
            onClick={hideShowSignUp}
          >
            {t("btn.signup")}
          </span>
        </span>
      )}
      {user != null && (
        <Drawer
          title={
            <div className="flex items-center justify-between">
              <LogoutButton label={t("btn.signout")} callbackUrl="/" />
              <div className="flex">
                <span className="text-right">
                  <h2 className="text-base font-semibold uppercase leading-none text-slate-700">
                    {user?.name}
                  </h2>
                  <p className="text-sm font-normal leading-tight text-slate-500">
                    {user?.email}
                  </p>
                </span>
                <span className="ml-2 rounded-full border border-slate-200 p-1">
                  <User2 className="text-slate-500" />
                </span>
              </div>
            </div>
          }
          onClose={onProfileClick}
          open={showSidebar}
          closable={false}
        ></Drawer>
      )}

      <Drawer onClose={hideShowSignUp} open={showSignUp} closable={false}>
        <SignUpForm />
      </Drawer>

      <Drawer onClose={hideShowSignIn} open={showSignIn} closable={false}>
        <SignInForm />
      </Drawer>
    </>
  );
}
