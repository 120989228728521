import React from "react";
import { Form, FormItemProps, Input, InputProps } from "antd";

import { AppConsts } from "src/lib/constants";

const { formVerticalLayout } = AppConsts;
interface FormInputProps extends FormItemProps {
  label?: string;
  name: string | string[];
  rule?: any;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  formClass?: string;
  inputProps?: InputProps;
  size?: "middle" | "small" | "large";
  type?: string;
  dependencies?: string[];
  onChange?: (value: any) => void;
  extra?: any;
}

const FormInput: React.FC<FormInputProps> = ({
  label,
  name,
  extra,
  rule,
  placeholder,
  disabled,
  className = "w-full rounded-md text-sm",
  formClass,
  inputProps,
  size,
  type,
  onChange,
}) => {
  return (
    <Form.Item
      label={label}
      name={name}
      rules={rule}
      className={"w-full" + (formClass ? " " + formClass : "")}
      extra={extra}
      {...formVerticalLayout}
    >
      <Input
        disabled={disabled}
        className={className}
        size={size ?? "large"}
        placeholder={placeholder}
        type={type}
        onChange={onChange}
        {...inputProps}
      />
    </Form.Item>
  );
};

export default FormInput;
