import(/* webpackMode: "eager" */ "/vercel/path0/portal/node_modules/@next/third-parties/dist/google/ga.js");
import(/* webpackMode: "eager" */ "/vercel/path0/portal/node_modules/@next/third-parties/dist/google/gtm.js");
import(/* webpackMode: "eager" */ "/vercel/path0/portal/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
import(/* webpackMode: "eager" */ "/vercel/path0/portal/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/vercel/path0/portal/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/vercel/path0/portal/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/vercel/path0/portal/src/components/layout-public/btn-profile/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/portal/src/components/layout-public/header-property.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/portal/src/components/layout-public/main-nav.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/portal/src/components/layout-public/nav.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/portal/src/images/logo.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/portal/styles/news-layout.scss")