"use client";
import { useRouter, useSearchParams } from "next/navigation";
import { Form, Select } from "antd";
import {
  useMasterDataStore,
  usePropertyStore,
} from "@stores/rootStoreProvider";
import FormInput from "@components/form-items/FormInput";
import { useCallback, useEffect, useState } from "react";
import { renderOptions } from "@components/form-items/FormSelect";
import { SearchIcon } from "lucide-react";
import { useTranslation } from "@/app/i18n/client";

export default function SearchProperty() {
  const propertyStore = usePropertyStore();
  const masterStore = useMasterDataStore();
  const searchParams = useSearchParams();
  const { t } = useTranslation("en", "common");
  const router = useRouter();
  const [form] = Form.useForm();
  const [propertyGroup, setPropertyGroup] = useState<any>([]);

  useEffect(() => {
    setPropertyGroup(masterStore.propertyGroups);
  }, []);

  // Get a new searchParams string by merging the current
  // searchParams with a provided key/value pair
  const createQueryString = useCallback(
    (name: string, value: string) => {
      const params = new URLSearchParams(searchParams.toString());
      params.set(name, value);

      return params.toString();
    },
    [searchParams],
  );

  const handleSubmit = async () => {
    form.validateFields().then(async () => {
      const values = form.getFieldsValue() || {};

      router.replace("/search?" + createQueryString("q", values.query ?? ""));
      propertyStore.setParams({
        ...propertyStore.params,
        q: values.query,
        group: values.group,
      });
    });
  };

  return (
    <div className="sticky top-0 z-[1001] flex h-[65px] items-center justify-center">
      <Form
        id="search-form"
        form={form}
        onFinish={handleSubmit}
        autoComplete="off"
        layout="vertical"
        className="custom-form relative flex h-[45px] w-[80vw] max-w-[710px] items-center rounded-3xl bg-slate-50 !px-5 sm:w-[65vw] lg:w-[50vw] xl:w-[35vw]"
      >
        <Form.Item name="group" className="mb-0">
          <Select
            className="search-group !focus:ring-0 w-[100px] !shadow-none ring-0"
            placeholder={t("all")}
          >
            {renderOptions(propertyGroup)}
          </Select>
        </Form.Item>
        <div className="mx-3 h-[24px] w-[1px] bg-slate-300"></div>

        <div className="flex flex-1">
          <FormInput
            name="query"
            placeholder={t("search")}
            className="!placeholder:text-slate-200 !h-auto min-h-[16px] w-full rounded-lg !border-none !bg-transparent !px-0 text-gray-900 !shadow-none ring-0 placeholder:text-sm
            focus:ring-0 sm:text-sm"
            formClass="mb-0"
          />
        </div>
        <div className="cursor-pointer">
          <SearchIcon
            size="18"
            className="text-slate-400"
            onClick={handleSubmit}
          />
        </div>
      </Form>
    </div>
  );
}
